<template>
    <div>

        <div class="card-toolbar mb-5">
            <router-link v-if="$can('pos_shifts.create')" to="/pos/pos-shifts/create"  class="btn btn-primary font-weight-bolder"><v-icon>mdi-plus</v-icon> {{ $t('pos_shifts.new_pos_shifts') }}</router-link>
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>

        <div class="card card-custom mb-5" v-if="showAdvancedSearch">
            <div class="card-body">
                <div class="m-form m-form--fit m--margin-bottom-20">
                    <div class="row">
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="transaction_code">{{$t('pos_shifts.name')}}</label>
                            <input v-model="filters.name" type="text" id="transaction_code" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('pos_shifts.parent_category')}}</label>
                            <treeselect
                                    :options="categories"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="filters.parent_category"
                                    @input="updateValue"
                                    :searchable="true"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="from_date">{{$t('pos_shifts.from_date')}}</label>
                            <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="to_date">{{$t('pos_shifts.to_date')}}</label>
                            <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                        </div>

                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label for="f_status">
                                {{$t('status')}}
                            </label>
                            <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
                            </select>
                        </div>
                        <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                            <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                            <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-body">
                <div>
                    <div class="row justify-content-end p-4">
                        <custom-export-data v-if="$can('pos_shifts.export_data')"  :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.pos_shifts')"></custom-export-data>
                    </div>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="status" slot-scope="props">
                        <b-form-checkbox v-if="$can('pos_shifts.change_status')" size="lg" @change="changeStatus(props.row.id, props.row.status)" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                        <b-form-checkbox v-else size="lg" :disabled="true" v-model="props.row.status" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2"  v-if="$can('pos_shifts.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('pos_shifts.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-pos-shifts",
        components: {},
        data() {
            return {
                mainRoute: 'pos/pos-shifts',
                subMainRoute: 'pos/pos-shift',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    parent_category: null,
                    name: '',
                    from_date: '',
                    to_date: '',
                    status: '',
                },
                inventory: null,
                status_list: [
                    {id: 1, text: this.$t('active')},
                    {id: 0, text: this.$t('inactive')},
                ],
                categories: [],


                columns: ['name', 'parent_category_name', 'added_by', 'created_at', 'status', 'actions'],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('pos_shifts.name')] = 'name';
                fields[this.$t('pos_shifts.parent_category')] = 'parent_category_name';
                fields[this.$t('pos_shifts.added_by')] = 'added_by';
                fields[this.$t('pos_shifts.created_at')] = 'created_at';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        name: that.$t('pos_shifts.name'),
                        parent_category_name: that.$t('pos_shifts.parent_category'),
                        added_by: that.$t('pos_shifts.added_by'),
                        created_at: that.$t('pos_shifts.created_at'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable:false,
                    customFilters: [{
                        name: 'alphabet',
                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.pos_shifts")}]);
            this.getCategories();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.parent_category = null;
                this.filters.name = '';
                this.filters.from_date = '';
                this.filters.to_date = '';
                this.filters.status = '';

                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'pos-shifts.edit', params: {id: item.id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            getCategories() {
                ApiService.get(this.mainRouteDependency + "/categories").then((response) => {
                    this.categories = response.data.data;
                });
            },

            changeStatus(id, status) {
                ApiService.patch(this.subMainRoute + '/change-status/' + id, {
                    status: (status ? '1' : '0'),
                }).then(response => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch(error => {
                    this.$errorAlert(error);
                });
            },


            loadOptions() {
            },
            updateValue(value) {
                this.filters.parent_category = value
            },
        },
    };
</script>
